import Axios from "axios"
import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import Container from "../components/UI/Container"
import Layout from "../components/UI/Layout"
import { API_URL } from "../constants/index"
import { Banner, BannerText, Paragraph, PrivacyWrapper } from "../styles/styles"

const Privaatsuspoliitika = () => {
  const [title, setTitle] = useState()
  const [content, setContent] = useState()

  useEffect(() => {
    Axios.get(`${API_URL}/pages/256`).then(res => {
      setTitle(res.data.title.rendered)
      setContent(
        res.data.blocks.map(block => {
          return block.innerContent
        })
      )
    })
  }, [])

  return (
    <>
      <Layout lang="et">
        <SEO title="Privaatsuspoliitika" />
        <Banner>
          <Container>
            <BannerText center>
              <h1>{title && title}</h1>
            </BannerText>
          </Container>
        </Banner>
        <Container>
          <PrivacyWrapper>
            <h2>{title && title}</h2>
            {content?.map(block => {
              return (
                <Paragraph>
                  <div dangerouslySetInnerHTML={{ __html: block }} />
                </Paragraph>
              )
            })}
          </PrivacyWrapper>
        </Container>
      </Layout>
    </>
  )
}

export default Privaatsuspoliitika
